<template>
  <v-container style="max-width: 1100px;">
    <!-- Breadcrums start here -->
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>

    <!-- Product Tab -->
    <v-row>
      <!-- Product Image -->

      <!--------------------------->
      <!-- 
      <v-col cols="12" sm="1" md="1" lg="1" xl="1">
        <v-row><h5 class="text-uppercase mb-3">Change View</h5></v-row>
        <v-row v-for="item in sideImgs" :key="item.src">
          <v-card
            ripple
            flat
            class="mb-1 px-1"
            height="80"
            width="80"
            style="border: 1px solid lightgrey; cursor: pointer;"
            @mouseover="sideHover(item.src)"
          >
            <v-img :src="item.src" aspect-ratio="1" contain></v-img>
          </v-card>
        </v-row>
      </v-col> -->
      <!------this is end for the image row----->

      <v-col lg="9" md="9" sm="12">
        <v-row>
          <v-col cols="2" class="mt-n6">
            <v-list-item>
              <v-list-item-content>
                <h5 class="mb-4 white text-uppercase">Edit Colors</h5>

                <v-list-item-title class="headline mb-1 ">
                  <span
                    v-for="(productColors,
                    index) in productDetail.productImgLayers"
                    :key="index"
                  >
                    <v-menu
                      open-on-hover
                      open-delay="5"
                      close-delay="120 "
                      close-on-content-click
                      offset-y
                      nudge-top="15"
                      nudge-right="35"
                      transition="fade-transition"
                    >
                      <template v-slot:activator="{ on }">
                        <v-card
                          ripple
                          v-on="on"
                          :color="productColors.color"
                          class="colorPallet ml-1 mb-n2"
                          flat
                          height="35"
                          width="35"
                          style="border: 1px dashed grey;"
                        >
                        </v-card>
                        <div class="overline">
                          {{ productColors.ShadeNumber }}
                        </div>
                      </template>

                      <div
                        class="pa-3 white"
                        style="width:410px; height: 370px; "
                      >
                        <v-row class="py-2 ">
                          <v-col>
                            <v-img
                              alt="Ramsha Carpets"
                              class="shrink"
                              contain
                              min-width="80"
                              src="/img/ramsha-black-logo.png"
                              width="120"
                            />
                          </v-col>

                          <v-spacer></v-spacer>
                        </v-row>
                        <v-row> </v-row>
                        <v-row class="white px-1">
                          <span
                            v-for="color in boxSel"
                            :key="color.ShadeNumber"
                          >
                            <v-menu
                              open-delay="0"
                              close-delay="10"
                              open-on-hover
                              right
                              offset-x
                              offset-y
                            >
                              <template v-slot:activator="{ on }">
                                <v-lazy>
                                  <v-sheet
                                    rounded
                                    v-on="on"
                                    height="32"
                                    width="42"
                                    class="ml-1 mt-n1"
                                    style="
                                    display: inline-block;
                                    cursor: pointer;
                                  "
                                    :color="color.HEX"
                                    @click="
                                      setColors(
                                        index,
                                        color.HEX,
                                        color.ShadeNumber
                                      )
                                    "
                                  >
                                  </v-sheet>
                                </v-lazy>
                                <p
                                  style="
                                    font-size: 10px;
                                    text-align: center;
                                    margin-top: -5px;
                                  "
                                >
                                  {{ color.ShadeNumber }}
                                </p>
                              </template>
                              <v-card>
                                <v-list-item-content class="px-2">
                                  <v-list-item-avatar
                                    height="160"
                                    tile
                                    :color="color.HEX"
                                    style="cursor: pointer; position:relative;"
                                  ></v-list-item-avatar>
                                  <v-img
                                    :src="palletImage"
                                    contain
                                    z-index="1"
                                    style="position:absolute;top:0;left:0; mix-blend-mode: soft-light;"
                                  />

                                  <v-list-item-subtitle
                                    class="pa-2 text-uppercase"
                                  >
                                    <strong>
                                      {{ color.ShadeNumber }}
                                    </strong>
                                    <div
                                      style="font-size: 20px;"
                                      class="overline"
                                    >
                                      {{ boxname }}
                                      <!-- <v-btn x-small depressed  color="primary">Buy Box</v-btn>  -->
                                    </div>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                              </v-card>
                            </v-menu>
                          </span>
                        </v-row>
                      </div>
                    </v-menu>
                  </span>
                  <!-----Random color button----->
                  <div>
                    <v-btn
                      dark
                      depressed
                      x-small
                      color="black"
                      @click="randomColor"
                      class="my-3 ml-1 px-2 py-4 elevation-0"
                    >
                      <v-icon dark small>
                        mdi-shuffle
                      </v-icon>
                    </v-btn>
                    <h6 class="overline mt-n3 mb-2">Random</h6>
                  </div>
                  <!------end for the random color button----->
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col>
            <div v-if="loading" style="position: relative;">
              <div
                justify="center"
                align="center"
                style="
                  max-width: 800px;
                  max-height: 500px;
                  top: 150px;
                  right: 300px;
                  position: absolute;
                  z-index: 20;
                "
              >
                <v-progress-circular indeterminate color="red">
                </v-progress-circular>
              </div>
            </div>
            <div v-else id="imgContainer" class="ml-0">
              <div id="baseimge">
                <v-img
                  id="backImg"
                  lazy-src="img/NoImageData.gif"
                  :src="currentObject.imgWindow"
                  alt="base"
                />
                <!-- :src="currentObject.imgWindow" -->
                <!-- </div> -->

                <!-- <div>
                
              > -->

                <!-- <div class="wool"></div> -->
                <svg
                  v-for="product in productDetail.productImgLayers"
                  :key="product.id"
                  :id="product.id"
                  class="base "
                  width="796"
                  height="496"
                  viewBox="-1 0 800 498"
                  v-html="product.path"
                  :style="{
                    fill: product.color,
                    stroke: product.color,
                    strokeWidth: '0px'
                  }"
                ></svg>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-list>
            <v-list-item>
              <v-card-title
                class="text-overline py-0 pl-0 text-uppercase font-weight-bold text-right"
                >Available Sizes (cm)
              </v-card-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-action class="mr-0">
                <v-chip-group
                  active-class=" primary--text"
                  column
                  mandatory
                  v-if="category == 'Majlis Carpets'"
                >
                  <v-chip
                    filter
                    filter-icon="mdi-check-decagram"
                    v-for="size in productDetail.majlisSizes"
                    @click="selectSizeMajlis(size)"
                    :key="size"
                    outlined
                  >
                    {{ size }}
                  </v-chip>
                </v-chip-group>
                <v-chip-group
                  v-else
                  active-class=" primary--text"
                  column
                  mandatory
                >
                  <v-chip
                    filter
                    filter-icon="mdi-check-decagram"
                    v-for="size in productDetail.productSize"
                    @click="selectSize(size)"
                    :key="size"
                    outlined
                  >
                    {{ size }}
                  </v-chip>
                </v-chip-group>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-row>
      </v-col>

      <!--------------------------->
    </v-row>
    <!-- Product Title Start -->
    <v-row
      class="pa-5 mb-4"
      style="border: 1px dashed lightgrey; border-radius: 8px;"
    >
      <v-col sm="6" class="text-left mx-auto">
        <v-row>
          <h2 class="text-h5">
            <span class="font-weight-bold text-uppercase">Design -</span>
            {{ productDetail.title }}
          </h2>
        </v-row>
        <!-- Product Title Ends -->

        <!-- Product Description Start -->
        <v-row>
          <p class="text-body-2 my-4">
            {{ productDetail.description }}
          </p>
        </v-row>

        <v-row>
          <v-card flat class="my-4">
            <v-card-title c class="pl-0 text-capitalize py-0">
              <v-simple-table>
                <tbody>
                  <tr
                    v-for="product in productDetail.productInfo"
                    :key="product.heading"
                  >
                    <td
                      class="text-uppercase font-weight-medium text-subtitle-2 grey-darken-1--text"
                    >
                      {{ product.heading }}
                    </td>
                    <td>{{ product.value }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-title>
          </v-card>
        </v-row>
      </v-col>
      <v-divider vertical inset></v-divider>

      <v-col sm="5" class="pt-3 mx-auto">
        <v-row>
          <CustomSizeForm
            :productDetail="productDetail"
            :user="user"
            :currentObject="currentObject"
            class="mt-5 mx-auto"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import colorBoxesData from "@/assets/box.json";
import CustomSizeForm from "@/components/CustomSizeForm.vue";
import { auth, db } from "../utils/firebase";
import { mapGetters } from "vuex";
import { toJpeg } from "html-to-image";

export default {
  name: "singleproduct",
  props: ["category"],
  components: { CustomSizeForm },
  data() {
    return {
      auth,
      showColors: false,
      unit: "feet & inches",
      snackbar: false,
      showSwatch: false,
      palletImage: "/img/patterImage.jpg",
      boxSel: colorBoxesData.Ramsha480,
      boxname: "Ramsha 480 BOX",
      loading: false,
      sideImgs: [
        { src: "/img/Lndscapes/JPEG/LD.jpg" },
        { src: `/img/Lndscapes/JPEG/LD-01.jpg` },
        { src: "/img/Lndscapes/JPEG/LD-02.jpg" },
        { src: "/img/Lndscapes/JPEG/LD-04.jpg" },
        // { src: "img/Lndscapes/JPEG/150R/LD-02.jpg" },
        { src: "/img/Lndscapes/JPEG/150R/LD-03.jpg" },
        // { src: "img/Lndscapes/JPEG/150R/LD-04.jpg" },
        { src: "/img/Lndscapes/JPEG/150R/LD-05.jpg" }
      ],
      breadcrumbs: [
        // {
        //   text: "Products",
        //   disabled: false,
        //   to: "/",
        // },
        {
          text: "Products",
          disabled: false,
          to: "/"
        },

        {
          text: "",
          disabled: true,
          to: "/products"
        }
      ],
      productDetail: {
        title: "",
        description: "",
        productImgLayers: [],
        productSize: [
          "170X240",
          "140X200",
          "120X180",
          "90X150",
          "60X180",
          "150R",
          "Custom Size"
        ],
        majlisSizes: ["250X350", "300X400", "350X500", "Custom Size"],
        productInfo: [
          {
            heading: "Design Code",
            value: "-"
          },
          {
            heading: "Product Type",
            value: "-"
          },
          {
            heading: "Pattern Type",
            value: "-"
          },
          {
            heading: "Material",
            value: "-"
          }
        ]
      },

      currentObject: {
        customSize: true,
        Size: "170X240",
        imgWindow: "/img/Lndscapes/JPEG/170X240/LD-01.jpg",
        currentImg: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  mounted() {
    this.setBox();
    this.getProduct(this.$route.params.id);
  },

  methods: {
    setImg() {
      let node = document.getElementById("imgContainer");
      toJpeg(node, { quality: "0.3" }).then(dataUrl => {
        this.currentObject.currentImg = dataUrl;
      });
      this.loading = false;
    },

    selectSize(size) {
      this.loading = true;
      if (size == "Custom Size") {
        this.currentObject.customSize = false;
        this.currentObject.imgWindow = `/img/Lndscapes/JPEG/170X240/LD-01.jpg`;
      } else {
        this.currentObject.customSize = true;
        this.currentObject.Size = size;

        this.currentObject.imgWindow = `/img/Lndscapes/JPEG/${size}/LD-01.jpg`;
      }
      this.setImg();
    },
    selectSizeMajlis(size) {
      this.loading = true;
      if (size == "Custom Size") {
        this.currentObject.customSize = false;
        this.currentObject.imgWindow = `/img/Lndscapes/JPEG/170X240/LD-01.jpg`;
      } else {
        this.currentObject.customSize = true;
        this.currentObject.Size = size;
      }
      this.setImg();
    },

    randomColor() {
      this.loading = true;
      for (let key in this.productDetail.productImgLayers) {
        let lyrObj = this.productDetail.productImgLayers[key];
        let ran = Math.floor(Math.random() * this.boxSel.length);
        if (lyrObj["color"] != this.boxSel[ran].HEX) {
          lyrObj["color"] = this.boxSel[ran].HEX;
          lyrObj["ShadeNumber"] = this.boxSel[ran].ShadeNumber;
        }
      }
      this.setImg();
      this.loading = false;
    },
    setColors(id, selectedColor, selectedShade) {
      this.loading = true;
      let productImage = this.productDetail.productImgLayers[id];
      productImage.color = selectedColor;
      productImage.ShadeNumber = selectedShade;
      this.setImg();
      this.loading = false;
    },

    sideHover(path) {
      this.currentObject.imgWindow = path;
    },
    setBox() {
      if (this.category == "Novara Rugs") {
        this.boxSel = colorBoxesData.Ramsha480;
        this.boxname = "Novara Box";
      } else {
        this.boxSel = colorBoxesData.Ramsha480;
        this.boxname = "Ramsha 480 Box";
      }
    },

    async getProduct(id) {
      this.loading = true;
      await db
        .collection("products")
        .doc(id)
        .get()
        .then(doc => {
          if (doc.exists) {
            this.productDetail.productImgLayers = doc.data().productLayers;
            this.productDetail.title = doc.data().deasignName;
            this.productDetail.description = doc.data().description;
            this.selectproductType = doc.data().productType;
            this.pattern = doc.data().patternType;
            this.breadcrumbs[1].text = doc.data().deasignName;
            this.productDetail.productInfo[0].value = doc.data().deasignName;
            this.productDetail.productInfo[1].value = doc.data().productType;
            this.productDetail.productInfo[2].value = doc.data().patternType;
            this.productDetail.productInfo[3].value = doc.data().material;
          } else {
            // doc.data() will be undefined in this case
          }
        })

        .catch(error => {
          console.log("Error getting document:", error);
        });
      setTimeout(() => {
        this.setImg();
      }, 500);
      this.loading = false;
      //  this.setImg()
    }
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Product",
    // all titles will be injected into this template
    titleTemplate: "%s | Ramsha Carpets"
  }
};
</script>

<style>
/*Quantity Lable and Size label modification*/
.v-input .v-label {
  font-size: 14px;
}
.v-text-field__suffix {
  font-size: 15px;
}

/* Image manipulators*/

#imgContainer {
  z-index: 1;
  position: relative;
  width: 500px;
  height: 500px;
}

.base {
  fill-rule: evenodd;
  clip-rule: evenodd;

  z-index: 3;
  top: 0;
  left: 0;
  mix-blend-mode: overlay;
  position: absolute;
  border-radius: 6px;
  filter: saturate(1.2) blur(0.03rem);

  /* -webkit-box-shadow: 2px 2px 4px 1px rgba(36, 36, 36, 0.63);
  box-shadow: 2px 2px 3px 1px rgba(128, 128, 128, 0.63); */
}

.wool {
  background: url("https://i.ibb.co/zmGyby8/wool.jpg") repeat;
  filter: contrast(1.2) grayscale(2);
  background-size: 123px;
  /* background: url('https://i.ibb.co/GsHPLBH/patter-Image.jpg') repeat  ;
   background-size: 160px; */
  /* background: url('https://i.ibb.co/y8RF1jz/0-Copy.jpg') repeat  ; */
  /* background-size: 200px; */
  width: 797px;
  height: 496px;
  opacity: 0.35;
  top: 0;
  left: 0;
  border-radius: 6px;
  /* mix-blend-mode: soft-light; */
  position: absolute;
  z-index: 6;
}

.loop {
  /* background: url("https://i.ibb.co/Gpk4VDs/loop.jpg"); */
  background: url("https://i.ibb.co/599cp1j/3-Copy.jpg");
  background-size: 155px;
  width: 797px;
  height: 496px;
  opacity: 0.35;
  /* mix-blend-mode: soft-light; */
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 6px;
  z-index: 6;
}
.viscose {
  background: url("https://i.ibb.co/J7rdjt9/1.jpg");
  background-size: 155px;
  width: 797px;
  height: 496px;
  opacity: 0.4;
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 6px;
  z-index: 6;
}

#baseimge {
  z-index: 1;
  position: absolute;
  background: rgb(252, 252, 252);
}
.colorPallet {
  cursor: pointer;
  display: inline-flex;
}
</style>

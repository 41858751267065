import { jsPDF } from "jspdf";

export const createPdf = (
  currentObject,
  productDetail,
  unit,
  user,
  quantity
) => {
  const fontUpperSize = 7;
  const colorSwatchesFontSize = 10;
  const detailsFont = 11;
  const primaryColor = "#898989";
  const secondryColor = "#000000";
  const borderColor = "#cccccc";
  const termsColor = "#BABABA";
  let nowDate = new Date();
  let spaceCounter = 17;
  let custcounter = 100;
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const productColors = productDetail.productImgLayers;

  let curentproductDet = currentObject;
  const customerDet = {
    Name: user.displayName ? user.displayName : "Not Available",
    Email: user ? user.email : "Not Available",
    Phone: user.phone ? user.phone : "Not Available",
    City: user.city ? user.city : "Not Available",
    Country: user.country ? user.country : "Not Available",
    Date:
      nowDate.getUTCDate() +
      "-" +
      monthNames[nowDate.getMonth()] +
      "-" +
      nowDate.getFullYear()
  };
  const terms = [
    "This Image is computer simulated hence the actual product colors are not accurate and may vary slightly due to your monitor settings.  ",
    "Hence this product is handmade and due to the nature of this product it may contain irregularities in th size.",
    "These variations are characteristic features of the manufacturing process and cannot be construct as flaws."
  ];

  const doc = new jsPDF("pl", "pt", "a4");
  // upper left details
  doc
    .setFontSize(fontUpperSize)
    .setTextColor(primaryColor)
    .text("Phone: +91 9621934333", 10, 15);
  doc.text("Email: support@ramshacarpets.com", 10, 25);
  doc.text("Web: www.ramshacarpets.com", 10, 35);
  doc.addImage("/img/LogoGrey.png", "png", 425, 12, 150, 23); // upper top right logo

  doc.addImage(curentproductDet.currentImg, "jpeg", 10, 55, 580, 363); //Main Product Image

  //seprator line
  doc
    .setLineWidth(0.5)
    .setLineDash([0], 0)
    .setDrawColor(borderColor)
    .line(0, 45, 600, 45);
  ///Image Color Details Box
  doc
    .setLineWidth(0.5)
    .setLineDash([2], 10)
    .setDrawColor(borderColor)
    .roundedRect(10, 430, 575, 70, 3, 3); //box line for colors
  doc
    .setFontSize(14)
    .setTextColor(secondryColor)
    .text("Colors", 15, 435); //header

  for (let index = 0; index < productColors.length; index++) {
    doc
      .setFillColor(productColors[index].color)
      .roundedRect(spaceCounter, 447, 28, 28, 3, 3, "FD");
    doc
      .setFontSize(colorSwatchesFontSize)
      .setTextColor(secondryColor)
      .text(productColors[index].ShadeNumber, spaceCounter, 490);
    spaceCounter += 40;
  }

  //product detail box
  let spaceCounter2 = 50;
  doc
    .setLineWidth(0.5)
    .setLineDash([2], 10)
    .setDrawColor(borderColor)
    .roundedRect(10, 515, 282, 180, 3, 3); //box line for colors
  doc
    .setFontSize(14)
    .setTextColor(secondryColor)
    .text("Product Details", 15, 520); //header

  for (let index = 0; index < productDetail.productInfo.length; index++) {
    doc
      .setFontSize(detailsFont)
      .setTextColor(secondryColor)
      .text(productDetail.productInfo[index].heading, 20, 505 + spaceCounter2);
    doc
      .setFontSize(detailsFont)
      .setTextColor(primaryColor)
      .text(
        ":    " + productDetail.productInfo[index].value,
        100,
        505 + spaceCounter2
      );
    spaceCounter2 += 25;
  }
  spaceCounter2 = 85;
  //product detail Size and Quantiy
  for (let x in curentproductDet) {
    let t = curentproductDet[x].toString();

    if (x != "imgWindow" && x != "customSize" && x != "currentImg") {
      doc
        .setFontSize(detailsFont)
        .setTextColor(secondryColor)
        .text(x, 20, 545 + spaceCounter2);
      doc
        .setFontSize(detailsFont)
        .setTextColor(primaryColor)
        .text(":    " + t, 100, 545 + spaceCounter2);
    }
    if (x == "Size" && t !== "150R") {
      doc
        .setFontSize(detailsFont)
        .setTextColor(primaryColor)
        .text(":    " + t + " " + unit, 100, 545 + spaceCounter2);
    }
    spaceCounter2 += 25;
  }
  doc
    .setFontSize(detailsFont)
    .setTextColor(secondryColor)
    .text("Quantity", 20, 680);
  doc
    .setFontSize(detailsFont)
    .setTextColor(primaryColor)
    .text(":    " + quantity + "", 100, 680);

  //customer detail box
  doc
    .setLineWidth(0.5)
    .setLineDash([2], 10)
    .setDrawColor(borderColor)
    .roundedRect(300, 515, 282, 180, 3, 3); //box line for colors
  doc
    .setFontSize(14)
    .setTextColor(secondryColor)
    .text("Customer Details", 305, 520); //header

  for (let y in customerDet) {
    var l = customerDet[y].toString();

    doc
      .setFontSize(detailsFont)
      .setTextColor(secondryColor)
      .text(y, 315, 455 + custcounter);
    doc
      .setFontSize(detailsFont)
      .setTextColor(primaryColor)
      .text(":    " + l, 365, 455 + custcounter);
    custcounter += 25;
  }
  //bottom line seprator
  doc
    .setLineWidth(0.5)
    .setLineDash([0], 0)
    .setDrawColor(borderColor)
    .line(0, 820, 600, 820);
  doc
    .setFontSize(7)
    .setTextColor(borderColor)
    .text("Copyright 2021 © Ramsha Carpets", 10, 835); //bottom left
  doc.setFontSize(7).text("www.ramshacarpets.com", 510, 835); //bottom right

  //terms and condition
  doc.setFont("times");
  doc
    .setFontSize(9)
    .setTextColor(termsColor)
    .text("Terms & Conditions", 20, 720);
  let ters = 20;
  for (let d = 0; d < terms.length; d++) {
    doc
      .setFontSize(8)
      .setTextColor(termsColor)
      .setFont("times", "italic")
      .text(d + 1 + ". " + terms[d], 30, 720 + ters);
    ters += 14;
  }

  //save function excution

  doc.save("Ramsha Carpets " + productDetail.title);

  let uriString = doc.output("datauristring");
  let design = productDetail.title;
  let data = new FormData();
  data.append("data", uriString);
  data.append("design", design);
  data.append("UID", user.uid);
  data.append("email", user.email);

  let xhr = new XMLHttpRequest();
  // xhr.onreadystatechange=function()
  //       {
  //         if (xhr.readyState==4 && xhr.status==200)
  //         {
  //          console.log(xhr.responseText)
  //         }
  //       }
  xhr.open("POST", process.env.VUE_APP_EMAIL_LINK, true);

  xhr.send(data);

  return "Thank you, Your quote has been submitted successfully!!";
};
